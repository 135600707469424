import React from "react";
import { Container, Flex, Box } from "theme-ui";
import ContentImages from "@solid-ui-components/ContentImages";
import Reveal from "@solid-ui-components/Reveal";
import StackTitle from "@solid-ui-components/StackTitle";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";

const styles = {
  item: {
    "& + &": {
      mt: [3, 5],
    },
  },
};

const FeaturesWithPhotoBlock04 = ({ content: { collection } }) => (
  <Container>
    <Box
      sx={{
        flexBasis: `1/3`,
        alignSelf: `center`,
        textAlign: [`center`, `left`],
        mx: [0, 4],
      }}
    >
      <Reveal effect="fadeInRight" threshold={0.7}>
        {collection?.map(({ kicker, title, text_1, text_2, images }, index) => (
          <Box key={`item-${index}`} sx={styles.item}>
            <ContentImages content={{ images }} />
            <StackTitle
              kicker={kicker}
              title={title}
              lines={[text_1, text_2]}
              align={[`center`, `left`]}
              space={0}
            />
          </Box>
        ))}
      </Reveal>
    </Box>
  </Container>
);

export default WithDefaultContent(FeaturesWithPhotoBlock04);
