import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import ModalWithTabs from "@solid-ui-blocks/Modal/Block01";
// import ModalSimple from "@solid-ui-blocks/Modal/Block02";
import Header from "@solid-ui-blocks/Header/Block01";
import NewsBlock from "@solid-ui-blocks/FeaturesWithPhoto/NewsBlock";
import Footer from "@solid-ui-blocks/Footer/Block01";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import theme from "./_theme";

const News = (props) => {
  let preSelectedLanguage = 'en';
  if (typeof window !== 'undefined') {
    preSelectedLanguage = localStorage.getItem("selectedLanguage");
  } 
  
  const { allBlockContent, site } = useStaticQuery(query);
  const [selectedlanguage, setSelectedlanguage] = useState(
    preSelectedLanguage
      ? preSelectedLanguage
      : site.siteMetadata.languages.defaultLangKey
  );

  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  function switchLanguage(lang) {
    setSelectedlanguage(lang);
  }

  if (typeof window !== 'undefined') {
    localStorage.setItem("selectedLanguage", selectedlanguage);
  }

  return (
    <Layout theme={theme} {...props}>
      <Seo title="Home" />
      {/* Modals */}
      <ModalWithTabs
        content={content[`authentication.${selectedlanguage}`]}
        reverse
      />
      <ModalWithTabs content={content[`contact.${selectedlanguage}`]} />
      {/* Blocks */}
      <Header
        switchLanguage={switchLanguage}
        languages={site.siteMetadata.languages.langs}
        content={content[`header.${selectedlanguage}`]}
      />

      <Divider space="5" />

      <NewsBlock content={content[`news.${selectedlanguage}`]} />

      <Divider space="5" />

      <Footer content={content[`footer.${selectedlanguage}`]} />
    </Layout>
  );
};

const query = graphql`
  query siteNewsBlockContent {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/news.en"
            "site/shared/header"
            "site/shared/footer"
            "site/shared/contact"
            "site/shared/authentication"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

export default News;
